.App {
  text-align: left;
  font-family: 'Inter', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body, input, button, textarea, select {
  font-family: 'Inter', sans-serif;
}

/* Override gaya default Semantic UI React */
.ui.input input,
.ui.button,
.ui.form .field label,
.ui.form .field input,
.ui.form .field textarea,
.ui.form .field select {
  font-family: 'Inter', sans-serif !important;
}

/* Override gaya default Bootstrap */
.btn,
.form-control,
.form-label,
.form-select,
.input-group-text {
  font-family: 'Inter', sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scroll-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 50s linear infinite;
}

.scroll-container {
  position: absolute;
  bottom: 1px;
  width: 100vw;
  background: #1d1d1d;
  overflow: hidden;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.hover-component {
    background: whitesmoke;
    border-radius: 5px;
    min-height: 250px;
    max-height: 250px;
    max-width: 250px;
    padding: 10px;
    font-size: 0.9em;
    border: 1px solid lightgrey;
    position: relative;
    overflow: hidden; 
}

.hover-content {
  position: absolute; 
  bottom: 100%; 
  left: 0;
  width: 100%; 
  background: lightgrey;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  opacity: 10; 
  transform: translateY(0); 
  transition: opacity 0.3s ease, transform 0.3s ease; 
}

.hover-component:hover .hover-content {
  opacity: 1; 
  transform: translateY(100%); 
}

.hover-component-locker {
  background: rgb(156, 232, 226);
  border-radius: 5px;
  min-height: 240px;
  max-height: 240px;
  max-width: 240px;
  padding: 10px;
  border: 0px solid #13a89e;
  position: relative; 
  overflow: hidden; 
}

.hover-component-locker-disable{
  background: rgb(232, 232, 232);
  border-radius: 5px;
  min-height: 240px;
  max-height: 240px;
  max-width: 240px;
  padding: 10px;
  border: 0px solid #9f9f9f;
  position: relative; 
  overflow: hidden; 
}

.hover-content-locker {
  position: absolute; 
  height: 45px;
  bottom: 100%; 
  left: 0;
  width: 100%; 
  background:rgba(19, 168, 158, 0.5);
  padding: 10px;
  opacity: 10; 
  border-radius: 0px 0px 0px 25px;
  transform: translateY(0); 
  transition: opacity 0.3s ease, transform 0.3s ease; 
  z-index: 100;
}

.hover-component-locker:hover .hover-content-locker {
  opacity: 1; 
  transform: translateY(100%); 
}

.hover-component-locker-disable:hover .hover-content-locker {
  opacity: 1; 
  transform: translateY(100%); 
}

.locker-number {
  color: #13a89e;
  font-size: 150px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: bolder;
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  z-index: 0;
}

.locker-number-disable {
  color: #bbbbbb;
  font-size: 150px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: bolder;
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  z-index: 0;
}

.locker-text-status {
  color: #13a89e;
  text-align: left;
  padding-top: 50px;
  font-size: 24px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: lighter;
}

.locker-text-status-disable {
  color: #bbbbbb;
  text-align: left;
  padding-top: 50px;
  font-size: 24px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: lighter;
}

